@import "../../styles/variables.scss";

@mixin button-bg($bg) {
  background: $bg;
  &:hover {
    background: darken($bg, 8%);
    transition: all 0.3s ease;
  }
  &:active {
    background: darken($bg, 25%);
  }
}

@mixin letter($color) {
  color: $color;
  &:hover {
    color: darken($color, 5%);
    transition: all 0.3s ease;
  }
}

@mixin border($color) {
  border: 2px solid $color;
}

.btn {
  cursor: pointer;
  text-decoration: none;
  border-radius: 40px;
  font-family: $barlow;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19.2px;
  letter-spacing: 1.33px;
  text-align: center;
  padding: 18px 32px;
  text-transform: uppercase !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid #E3E829;
  &__icon {
    font-size: 22px;
    text-align: center;
    color: $secondary;
  }

}

.btn-none {
  display: none;
}

.btn-secundary {
  @include button-bg($secondary);
  @include letter($secondary_white);
  @include border(#00aec7);
}

.btn-primary {
  @include button-bg($secondary_yellow);
  @include letter($primary);
}

.btn-primary-blue {
  @include button-bg($primary);
  @include letter($secondary_white);
  @include border($primary)
}

.btn-secundary-white {
  @include button-bg($secondary_white);
  @include letter(#0033a0);
  @include border($secondary_white);
}

.btn-ghost {
  @include button-bg(transparent);
  @include letter($secondary_white);
  @include border($secondary_white);
}

.btn-ghost-variant {
  @include button-bg($secondary_white);
  @include letter($primary);
  @include border($secondary);
}

.btn-tertiary-white {
  background: $gray-lighten-0;
  @include letter($primary);
  @include border($primary);
}

.btn-grey {
  @include button-bg($gray_lighten-1);
  @include letter($gray_darken);
  @include border($gray_lighten-1);
  cursor: not-allowed;
}
