@import '../../styles/variables.scss';
@import "./../../styles/mixins/mixin.scss";

.main_item_contain {
    display: flex;
    flex-direction: column;
}
.item_title {
    margin-bottom: 10px;
    .number {
        font-family: $fs-joey;
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 45px;
        letter-spacing: 0.25px;
        color: $secondary_yellow;
    }
    .title {
        font-family: $barlow;
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: 1.25px;
        color: $secondary_white;
        @include breakpoint(xs) {
            padding-bottom: 0.5rem;
        }
    }
}

.item_contenedor {
    display: flex;
    flex-flow: row nowrap;
}

.item_circle {
    width: 22%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .circle {
        // width: 80px;
        // height: 80px;
        border-radius: 100%;
        // background: #00acc742;
        display: flex;
        justify-content:center;
        align-items: center;
        margin-left: 5%;

        .icon {
            color: $primary;
            display: flex;
            align-items: center;
            justify-content: center;
            @include breakpoint(xs){
                font-size: 12px !important;
            }
            img {
                width: 100%;
            }
        }
    }
}

.item_description {
    width: 85%;
    padding-left: 0.75rem;
    @include breakpoint(xs) {
        padding-left: 1rem;
    }
    padding-right: 2.5%;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    h1 {
        font-family: $barlow;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: 0.157143px;
        @include breakpoint(xs) {
            font-size: 16px;
        }
    }
    p {
        font-family: $barlow;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
}