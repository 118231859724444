.wrapper {
  &__outer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__content {
    display: flex;
    flex-direction: column;
  }
}