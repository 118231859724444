@import "./../../styles/variables.scss";

.divider {
  border-bottom: 4px solid #F6DF67;
  width: 24px;
  border-radius: 10px;
}
.item {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  padding-top: 20px;
  margin: 20px;
  
  &__border {
    border-top: 1px solid #CCD6EC;
}
  
  &__avatar {
    &__img {
      vertical-align: middle;
      width: 85px;
      height: 85px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  &__content {
    &__title {
      font-size: 18px;
      line-height: 28px;
      font-weight: 700;
      font-style: normal;
      font-family: $barlow;
      margin-bottom: 8px;
      color: $primary;
      text-transform: uppercase;
    }
    &__paragraph {
      font-size: 18px;
      font-weight: 400;
      font-family: $barlow;
      line-height: 24px;
      color: $gray-darken;
      width: 100%;
    }
  }
}
