@import '../../styles/variables.scss';
.card_img {
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    height: 200px;
    object-fit: cover;
    z-index: 5;
    min-height: 200px;
    img {
        width: 100%;
        height: 100%;
    }
}

.card_container {
    width: 100%;
    height: 100%;
    picture{
        height: 200px;
    }
    .card_chip_title {
        display: flex;
        margin-bottom: 6.03px;
    
        .chip_title {
            margin-left: 5.7%;
            font-family: $barlow;
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 26px;
            display: flex;
            align-items: center;
            letter-spacing: 0.157143px;
            color: $dark;
        }
    
        .title {
            font-family: $barlow;
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 26px;
            display: flex;
            align-items: center;
            letter-spacing: 0.157143px;
            color: $dark;
        }
    
    }
    
    p {
            font-family: $barlow;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $gray_darken;
            margin-bottom: 7.2px;
        }
    .card_chip_desciption {
        width: 100%;
        padding: 8px 40px 35px 40px;
        color: #53565A;
        p{
            display: -webkit-box !important;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            white-space: normal;
            height: 100px;
            overflow: hidden;
        }
    }
    
}
.card_chip_info {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
        font-family: $barlow;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 1.33333px;
        text-transform: uppercase;
        color: $secondary;
        margin-right: 10px;
    }

    .svg {
        font-size: 15px;
        color: $secondary;
    }
}

.card_slider_container {
    position: relative;
    overflow: hidden;
    background: $secondary_white;
    border-radius: 20px;
        &_slider {
            display: flex;
            flex-wrap: nowrap;
            &_slide {
                min-width: 100%;
                overflow: hidden;
                transition: 0.3s ease all;
                z-index: 100;
                max-height: 100%;
                position: relative;
                height: 460px;
            }
        }
        &_text {
            margin: auto;
            width: 738px;
            padding: 51px 92px 0px 106px;
            height: 439px;
            display: flex;
            align-items: center;

            div {
                p {
                    margin-top: 5px;
                    white-space: pre-wrap;
                }    
            }
        }

        &_footer {
            display: flex;
            margin-top: 20px;
            justify-content:flex-start;

            &_icon {
                font-size: 40px;
                margin-top: -5px;
            }
            &_bar {
                margin-top: 14px;
                width: 50.47px;
                height: 1px;
                border: 1px solid #676F8F ;
                transform: rotate(90deg);
            }

            &_text {
                margin-top: -10px;
                h5 {
                    font-family: $barlow;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 28px;
                    color: $primary;
                }
                span {
                    font-family: $barlow;
                }
            }
        }
    
}

.card_slider_controls {
    position: absolute;
    top: 0;
    z-index: 200;
    width: 100%;
    height: 100%;
    pointer-events: none;

    button {
        pointer-events: all;
        background: none;
        border: none;
        cursor: pointer;
        outline: none;
        width: 50px;
        height: 100%;
        text-align: center;
        position: absolute;
        transition: 0.3s ease all;
        color: $secondary;
        font-size: 50px;
    }
    &_right {
        right: 0;
        padding-right: 4rem;
    }
    &_left {
        left: 0;
        padding-left: 3rem;
    }
}

.card__information {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-header {
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.card-header img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.card__information__tags {
    display: flex;
    padding: 15px 0;
    .chip span{
        font-weight: bold;
    }
}

.card__title_blog {
    font-size: 28px !important;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.tags-flex-wrap {
    flex-wrap: wrap;
}

.tags-reponsive {
    padding: 3px 7px !important;
}
.header-api {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.letter__spacing{
    letter-spacing: 1.5px;
}

.no__shadow{
    box-shadow: none !important;
}

.custom_card__height{
    height: 461px !important;
    @include breakpoint(xs) {
        height: 100% !important;
    }
    .card-header{
        height: 133px;
        @include breakpoint(xs) {
            height: 180px;
        }
    }
    .body-2{
        height: 70px !important;
        overflow: hidden;
    }
    .hidden__tags{
        @include breakpoint(xs) {
            display: none;
        }
    }
    // .card_chip_info{
    //     @include breakpoint(md) {
    //         display: none;
    //     }
    // }
    // card-header img {
    //     width: 100%;
    //     height: 100%;
    //     object-fit: cover;
    //   }
}

.green {
    display: flex;
    font-size: 15px;
    line-height: 16px;
    align-items: center;
    justify-content: space-between;
    color: #97D700;
    }
    
    .warning {
        display: flex;
    font-size: 15px;
    line-height: 16px;
    align-items: center;
    justify-content: space-between;
    color: #F1B434;
}
    .red {
        display: flex;
    font-size: 15px;
    line-height: 16px;
    align-items: center;
    justify-content: space-between;
    color: #e91818;
}


// lIBRARY CARD
.description {
    text-overflow: ellipsis;
    width: 365px;
    white-space: nowrap;
    overflow: hidden;
}