@import "../../../styles/variables.scss";

.wrapper {
    width: 100%;
    margin-top: 150px;
    display: flex;
    justify-content: center;
    flex-flow: nowrap row;
    // padding: 0% 3.5%;

    @include breakpoint(xs) {
      margin-top: 80px;
    }

    &__left {
        margin-bottom: 2rem;
        display: none;
        visibility: hidden;

        @include breakpoint(lg) {
          display: block;
          visibility: visible;
        }
    }
    &__right {
        width: 80%;
        padding: 0 0 2% 4%;

        &__control_container {
          margin-bottom: 1rem !important;

          @include breakpoint(md) {
            margin-bottom: 0 !important;
          }
          
          &:last-child {

            @include breakpoint(md) {
              padding-left: 0 !important;
            }
          }

        }

        @include breakpoint(xs) {
          width: 100%;
          padding: 0 15px;
          margin-bottom: 20px;
        }
        
        @include breakpoint(md) {
          width: 100%;
          padding: 0;
          margin-bottom: 20px;
        }
        
        @include breakpoint(lg) {
          width: 80%;
          padding: 0 0 2% 11%;
        }
    }
    &__filters {
      margin-top: 1.5rem;
      margin-left: 1rem;
      display: flex;
      color: rgba(0, 0, 0, 0.38);
    }
    &__reset {
      background: none;
      border: none;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      // line-height: 16px;
      color: rgba(0, 0, 0, 0.38);
      cursor: pointer;
      text-transform: uppercase;
      letter-spacing: 1.5px;

    }
}

.wrapper__checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__counter {
    display: flex;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: #e3e8e9;
    color: #000;
    margin: 0.5rem 0;
    text-align: center;
  }
}
// .box {
// //   position: absolute;
// //   top: 50%;
// //   left: 50%;
// //   transform: translate(-50%, -50%);

//   select {
//     background-color: #0563af;
//     color: white;
//     padding: 12px;
//     width: 250px;
//     border: none;
//     font-size: 20px;
//     box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
//     -webkit-appearance: button;
//     appearance: button;
//     outline: none;

//     option {
//       padding: 30px;

//     }
//   }

//   &::before {
//     content: "\f13a";
//     font-family: FontAwesome;
//     position: absolute;
//     top: 0;
//     right: 0;
//     width: 20%;
//     height: 100%;
//     text-align: center;
//     font-size: 28px;
//     line-height: 45px;
//     color: rgba(255, 255, 255, 0.5);
//     background-color: rgba(255, 255, 255, 0.1);
//     pointer-events: none;
//   }

//   &:hover::before {
//   color: rgba(255, 255, 255, 0.6);
//   background-color: rgba(255, 255, 255, 0.2);
// }

// }

.box {
  position: relative;
  background-color: transparent;
  width: auto;
  border: 1px black solid;
  float: left;
  max-width: 100%;
  width: 500px;
  border-radius: 20px;
  select {
    font-family: "helvetica neue", helvetica;
    font-size: 1rem;
    font-weight: 200;
    max-width: 100%;
    padding: 8px 24px 8px 10px;
    border: none;
    background-color: transparent;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  &:after {
    content: " ";
    position: absolute;
    top: 50%;
    margin-top: -2px;
    right: 8px;
    // width: 0; 
    height: 0;
    width: 0;
    // height: 100%; 
    // background-color: rgba(255, 255, 255, 0.1);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #aaa;
  }
}
