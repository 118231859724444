@import "../../../styles/variables.scss";
@import "../../../styles/mixins/mixin.scss";

.main__admin {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    // padding: 0 30px;
    margin-bottom: 2.5rem;
    &__suscription {
        margin-bottom: 87px !important;
    }
        
}
.admin {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;

    &__form {
        padding: 2.5rem 0;
        box-shadow: 0px 4px 28px rgba(169, 177, 209, 0.12);
        background: #fff;
        border-radius: 12px;
        width: 100%;
    }

    &__form__container {
        padding: 0 3rem;

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1rem;

            &__avatar{
                cursor: pointer;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 80px;
                height: 80px;
                border: 3px solid #0033A0;
                border-radius: 100%;

                &__input {
                    display: none;
                }

                &__icon{
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 25px;
                    height: 25px;
                    bottom: 0%;
                    right: 0%;
                    background: #0033A0;
                    color: #fff;
                    border-radius: 100%;
                }
            }
        }
        &__button {
            margin-top: 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    }
&__filter__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    flex-direction: column;
    background-color: #fff;
    border-radius: 0 0 10px 10px;
    box-shadow: 0px 2px 20px #ECF0F1;
    overflow: hidden;
    max-height: 0;
    transition: all 0.3s ease-in-out;
    overflow-y: scroll;
    overflow-x: hidden;
}
}

.margin_left {
    margin-left: 5%;
}

.wrapper_subscriptions__wide {
    &__display{
      display: block;
    }
    @include breakpoint(xsl) {
      &__display{
        display: none;
      }
    }
  }
  
  .wrapper_subscriptions__small {
    &__display{
      display: none;
    }
    @include breakpoint(xsl) {
      &__display{
        display: block;
      }
    }
  }