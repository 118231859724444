@import "../../styles/variables.scss";

.title {
  font-family: $fs-joey;
  font-size: 2.5rem;
  font-weight: 400;
  letter-spacing: -0.6px;
  &__name {
    font-family: $fs-joey;
    font-weight: 500;
    font-size: 2.5rem;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__developer {
    font-weight: 700;
    font-size: 0.75rem;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    color: $gray_darken;
  }
  &__organization {
    font-weight: 400;
    font-size: 1.25rem;
    color: $gray_darken;
  }
  &__role {
    font-weight: 600;
    font-size: 1.25rem;
    color: $gray_darken;
  }
}