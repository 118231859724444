@import "../../../styles/variables.scss";

.navbar {
  background: $primary;
  color: $secondary_white;
  width: 100%;
  height: 50px;
  padding: 2rem;
  display: flex;
  align-items: center;
  &__content {
    width: 90%;
    margin: 0 auto;
  }
}
.wrapper {
  height: 69vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  &__content {
    &__text {
      font-weight: 400;
      color: $primary;
      text-align: center;
      h1 {
        font-size: 2rem;
        margin-bottom: 0.5rem;
      }

      p {
        font-size: 1.2rem;
        font-family: $fs-joey;
        color: $gray_darken;
        margin-bottom: 1rem;
      }
    }
  }
}
