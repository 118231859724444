@import "./../../../styles/variables.scss";
@import "../../../styles/mixins/mixin.scss";

.create-account {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  // box-sizing: border-box;

  .input_field {
    width: 48%;
    padding: 0 10px;
    margin-top: 20px;
  }

  button {
    width: 50%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .create-account {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    height: 70vh;
    overflow: auto;

    // div {
    //   width: 100%;
    //   padding: 0 10px;
    //   margin: 8px 0;
    // }
  }
}

.create-account__checkbox {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  // margin-top: 20px !important;
  color: $primary;

  input[type="checkbox"] + span {
    font-weight: 400;
    font-size: 0.938rem;
  }

  input[type="checkbox"] {
    width: 18px;
    height: 18px;
    border: 2px solid $primary;
    border-radius: 3px;
  }  
  input[type="checkbox"]:checked {
    background-color: $primary;
  }

  // input {
  //     margin-right: 10px;

  //     @media (max-width: 715px) {
  //         margin-right: 9px;
  //         margin-bottom: 16px;
  //     }
  // }

  // @media (max-width: 768px) {
  //     text-align: center;
  // }
}

.text-bold {
  font-weight: bold;
  text-decoration-line: underline;
}

.footer-register {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
}

.footer-register-button {
  grid-column: 5/9;
}

.register {
  &__btn {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    @include breakpoint(xsl) {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }
    // .btn-secundary-white {
    //   padding: 0 !important;
    // }
    // button {
    //   width: 200px;
    //   margin-right: 10px;
    //   div {
    //     width: 100% !important;
    //     min-width: 200px;
    //   }
    //   @include breakpoint(xsl) {
    //     width: 100%;
    //     margin-bottom: 10px;
    //   }
    // }
  }
}

.input__checkbox {
  display: flex;
  gap: 0.5rem;
  align-items: baseline;
  p{
    @include breakpoint(xs) {
      font-size: 13px;
    }
    letter-spacing: 0.25px;
    font-weight: 400;
    color: $primary;
    span, a {
      font-weight: 700;
      text-decoration: underline;
    }
  }
}

.wrapper__register {
  @include breakpoint(sm) {
    width: 90%;
    margin: 0 auto;
    position: relative;
  }
}
