@mixin breakpoint($point) {
  @if $point == xl {
    // 1200px
    @media only screen and (min-width: 1200px) {
      @content;
    }
  } 
  @if $point == lg {
    // 992px
    @media only screen and (min-width: 992px) {
      @content;
    }
  }
  @if $point == md {
    // 768px
    @media only screen and (min-width: 768px) {
      @content;
    }
  }
  @if $point == sm {
    // 600px
    @media only screen and (min-width: 600px) {
      @content;
    }
  }
  @if $point == xsl {
    // 600px
    @media only screen and (max-width: 900px) {
      @content;
    }
  }
  @if $point == xs {
    // 600px and down
    @media only screen and (max-width: 600px) {
      @content;
    }
  }  
}

/* ------------------------------ 
    FLEXBOX 
------------------------------ */
@mixin d-flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex-direction($direction: row) {
  @if $direction == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
    -moz-box-direction: reverse;
    -moz-box-orient: horizontal;
  } @else if $direction == column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-direction: normal;
    -moz-box-orient: vertical;
  } @else if $direction == column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
    -moz-box-direction: reverse;
    -moz-box-orient: vertical;
  } @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -moz-box-direction: normal;
    -moz-box-orient: horizontal;
  }
  -webkit-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

@mixin flex-wrap($value: nowrap) {
  -webkit-flex-wrap: $value;
  @if $value == nowrap {
    -ms-flex-wrap: none;
  } @else {
    -ms-flex-wrap: $value;
  }
  flex-wrap: $value;
}

@mixin justify-content($value: flex-start) {
  @if $value == flex-start {
    -webkit-box-pack: start;
       -moz-box-pack: start;
       -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
       -moz-box-pack: end;
       -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
       -moz-box-pack: justify;
       -ms-flex-pack: justify;
  } @else if $value == space-around {
       -ms-flex-pack: distribute;
  } @else {
    -webkit-box-pack: $value;
       -moz-box-pack: $value;
       -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
          justify-content: $value;
}

@mixin align-content($value: stretch) {
  // No Webkit Box Fallback.
  -webkit-align-content: $value;
  @if $value == flex-start {
    -ms-flex-line-pack: start;
  } @else if $value == flex-end {
    -ms-flex-line-pack: end;
  } @else {
    -ms-flex-line-pack: $value;
  }
  align-content: $value;
}

@mixin grid($number) {
  flex: 0 0 percentage((100 / $number) / 100);
  max-width: percentage((100 / $number) / 100);
}

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color; /* The Fallback */
  background: rgba($color, $opacity);
}

@mixin button-bg($bg) {
  background: $bg;
  &:hover {
    background: darken($bg, 8%);
    transition: all 0.3s ease;
  }
  &:active {
    background: darken($bg, 25%);
  }
}

@mixin letter($color) {
  color: $color;
  &:hover {
    color: darken($color, 5%);
    transition: all 0.3s ease;
  }
}

@mixin border($color) {
  border: 2px solid $color;
}