@import "../../../styles/variables.scss";

.return {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 64px;

  div {
    color: $secondary;
    font-size: 20px;
  }

  span {
    font-family: $barlow;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    letter-spacing: 1.33333px;
    text-transform: uppercase;
    color: $secondary;
  }
}
.back__btn {
  position: absolute;
  top: -42px;
  left: 30px;

  @include breakpoint(xs) {
    left: 0;
  }

  @include breakpoint(sm) {
    left: 0;
  }  

  @include breakpoint(lg) {
    left: 30px;
  }

}

// sx={{ display: 'grid', gridTemplateColumns: '1fr 4fr', gap: '3rem', alignItems: 'baseline' }}
.grid__apidetail {

  &__accordionfilter {
    grid-area: 'accordionfilter';
    @include breakpoint(xs) {
      display: none;
    }
    @include breakpoint(sm) {
      display: none;
    }
    @include breakpoint(lg) {
      display: block;
    }
  }
  &__customaccodion {
    grid-area: 'customaccordion';
  }

  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 3rem;
  align-items: baseline;
  
  @include breakpoint(xs) {
    grid-template-columns: 1fr;
    gap: 0;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }
  @include breakpoint(sm) {
    grid-template-columns: 1fr;
    gap: 0;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }  
  
  @include breakpoint(lg) {
    grid-template-columns: 1fr 4fr;
    gap: 3rem;
    justify-content: center;
    align-items: baseline;
  }

}

.wrapper__btn {
  background: $secondary_yellow;
  border-radius: 100px;
  padding: 0.75rem 1rem;
  width: 6rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: 0.8px;
  color: $primary;
}

.box__title {
  h1 {
    @include breakpoint(xs) {
      font-size: 2.25rem;
    }
  }
}

