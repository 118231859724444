@import "./../../styles/variables.scss";
@import "./../../styles/mixins/mixin.scss";

.blog_list {
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  padding: 0 1.5rem 1rem;
  margin-top: 1.5rem;

  @include breakpoint(md) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.full_blog_list {
  grid-template-columns: repeat(1, 1fr);

  @include breakpoint(sm) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoint(md) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.separator {
  display: block; height: 1px;
  border: 0; border-top: 1px solid #ecf0f1;
  margin: 30px 1.5rem;
  padding: 0;
}

.pagination {
  width: 100%;
  display: flex;
  list-style: none;
  align-content: space-between;
  justify-content: space-between;
  padding: 0 1.5rem;
  margin-bottom: 1.5rem;
}

.previous {
  color: #0033a0;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 2px;
  height: 32px;

  @include breakpoint(sm) {
    margin-right: 15%;
  }

  @include breakpoint(md) {
    margin-right: 25%;
  }

  &::before {
    content: url("data:image/svg+xml,%3Csvg stroke='%2300aec7' fill='%2300aec7' stroke-width='0' viewBox='0 0 24 24' height='30px' width='30px' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='none' d='M0 0h24v24H0z'%3E%3C/path%3E%3Cpath d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z'%3E%3C/path%3E%3C/svg%3E");
    left: -10px;
    position: relative;
  }

  &__link {
    margin: 0;
    cursor: pointer;
    display: flex;
    position: relative;
    top: -40px;
    padding-left: 20px;
  }
}

.next {
  color: #0033a0;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 2px;
  height: 32px;

  @include breakpoint(sm) {
    margin-left: 15%;
  }

  @include breakpoint(md) {
    margin-left: 25%;
  }

  &::after {
    content: url("data:image/svg+xml,%3Csvg stroke='%2300aec7' fill='%2300aec7' stroke-width='0' viewBox='0 0 24 24' height='30px' width='30px' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='none' d='M0 0h24v24H0z'%3E%3C/path%3E%3Cpath d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z'%3E%3C/path%3E%3C/svg%3E");
    left: 74px;
    position: relative;
    top: -32px;
  }

  &__link {
    margin: 0;
    cursor: pointer;
    display: flex;
    position: relative;
    padding-right: 20px;
  }
}

.number_page {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 2px;
  height: 32px;
  color: #0033a0;
  cursor: pointer;
}

.number_page_active {
  color: #00aec7;
}

.disabled {
  visibility: hidden;
}