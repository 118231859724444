@import "../../../styles/variables.scss";
@import "../../../styles/mixins/mixin.scss";

.title {
  font-size: 48px;
  font-weight: 700;
  color: $primary;
  margin-bottom: 0.5rem;
}

.cell_title {
  display: flex;
  font-size: 20px;
  color: $gray_lighten-2;

  h2 {
    font-family: $barlow;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: #53565a;
  }
}

.cell_name {
  font-family: $barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;

  display: flex;
  align-items: center;
  letter-spacing: 0.2px;

  color: $secondary;
}

.cell_description {
  font-family: $barlow;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #53565a;
}

.pagination {
  cursor: pointer;
  display: flex;
  color: $primary;
  font-size: 20px;
  align-items: center;

  &__icon {
    color: $secondary;
  }

  p {
    font-family: $barlow;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
  }

  .next {
    margin-left: -20px;

    @media (min-width: 1440px) {
      margin-left: -10px;
    }
  }
}

.gridEnd {
  display: flex;
  justify-content: flex-end;
}

.colorBtn {
  color: $primary;
  font-weight: bold;
}

.buttonApi {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 80%;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  margin-top: 20px;
}

.form_suscriptione {
  display: flex;
  align-items: center;
  // justify-content:space-between;
  @include breakpoint(xsl) {
    flex-direction: column;
  }

  &__input {
    width: 600px;
    margin-right: 28px;
    height: 36px;
    @include breakpoint(xsl) {
      width: 100%;
      margin-right: 0;
      div {
        min-width: 100%;
      }
    }
  }

  &__btn {
    width: 167px;
    height: 36px;
    @include breakpoint(xsl) {
      margin-top: 15px;
      width: 100%;
    }
  }
}

.return {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 64px;

  div {
    color: $secondary;
    font-size: 20px;
  }

  span {
    font-family: $barlow;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    letter-spacing: 1.33333px;
    text-transform: uppercase;
    color: $secondary;
  }
}

.input {
  width: 100%;
  border: none;
  border-radius: 15px;
  outline: none;
  padding: 5px 10px;
  font-size: 0.9rem;
  color: #000;
  margin: 0;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 2px 20px #ecf0f1;
  &:focus {
    border-color: aqua;
  }
}

.back__btn {
  position: absolute;
  top: -42px;
  left: 30px;
  
  @include breakpoint(xs) {
    left: 0;
  }
  

  @include breakpoint(sm) {
    left: 0;
  }

  @include breakpoint(lg) {
    left: 30px;
  }
}



.wrapper_subscriptions__wide {
  &__display{
    display: block;
  }
  @include breakpoint(xsl) {
    &__display{
      display: none;
    }
  }
}

.wrapper_subscriptions__small {
  &__display{
    display: none;
  }
  @include breakpoint(xsl) {
    &__display{
      display: block;
    }
  }
}

.wrapper_apps__wide {
  &__display{
    display: block;
  }
  @include breakpoint(xsl) {
    &__display{
      display: none;
    }
  }
}

.wrapper_apps__small {
  &__display{
    display: none;
  }
  @include breakpoint(xsl) {
    &__display{
      display: block;
      .border__bottom{
          border-bottom: 1px solid #ECF0F1;
      }
    }
  }
}