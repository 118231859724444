@import "./tools/tools.scss";
@import "./mixins/mixin.scss";

/* ------------------------------ 
    FONTS 
------------------------------ */

@font-face {
  font-family: "Sura";
  src: url("../static/fonts/SuraSans-Regular.otf");
  font-weight: normal;
  font-style: normal;
}

$barlow: Arial, sans-serif;
$fs-joey: "Sura";

/* ------------------------------ 
    COLORS 
------------------------------ */
$primary: #0033a0;
$secondary: #00aec7;
$secondary_white: #ffffff;
$secondary_yellow: #e3e829;
$gray: #768692;
$gray-lighten-0: #F5F6F8;
$gray_lighten-1: #ecf0f1;
$gray_lighten-2: #8189a9;
$gray_lighten-3: #8b92b1;
$gray_lighten-4: #696974;
$gray_darken: #53565a;
$success: #389e0d;
$dark: #000;
$error: #f54b5e;
$warning: #f1b434;
$green_sura: #97D700;
$blue_sura: #003C71;
$orange_sura: #F1B434;
$red_sura: #F54B5E;

/* ------------------------------
    WEIGHTS 
------------------------------ */
$font-weights: () !default;
$font-weights: map-deep-merge(
  (
    "thin": 100,
    "light": 300,
    "regular": 400,
    "medium": 500,
    "semi-bold": 600,
    "bold": 700,
    "black": 900,
  ),
  $font-weights
);

/* ------------------------------ 
    HEADINGS 
------------------------------ */
$heading: () !default;
$heading: map-deep-merge(
  (
    "headline-ad": (
      "size": 90px,
      "weight": 700,
      "line-height": auto,
      "letter-spacing": -1.5px,
      "font-family": $fs-joey,
      "text-transform": false,
    ),
    "h1": (
      "size": 60px,
      "weight": 700,
      "line-height": auto,
      "letter-spacing": -0.5px,
      "font-family": $fs-joey,
      "text-transform": false,
    ),
    "h2": (
      "size": 48px,
      "weight": 700,
      "line-height": auto,
      "letter-spacing": auto,
      "font-family": $fs-joey,
      "text-transform": false,
    ),
    "h3": (
      "size": 36px,
      "weight": 700,
      "line-height": auto,
      "letter-spacing": 0.25,
      "font-family": $barlow,
      "text-transform": false,
    ),
    "h4": (
      "size": 28px,
      "weight": 600,
      "line-height": auto,
      "letter-spacing": auto,
      "font-family": $barlow,
      "text-transform": false,
    ),
    "h5": (
      "size": 22px,
      "weight": 500,
      "line-height": auto,
      "letter-spacing": 0.15,
      "font-family": $barlow,
      "text-transform": false,
    ),
    "subtitle-1": (
      "size": 18px,
      "weight": 400,
      "line-height": auto,
      "letter-spacing": 0.15,
      "font-family": $barlow,
      "text-transform": false,
    ),
    "subtitle-2": (
      "size": 16px,
      "weight": 500,
      "line-height": auto,
      "letter-spacing": 0.1,
      "font-family": $barlow,
      "text-transform": false,
    ),
    "body-1": (
      "size": 18px,
      "weight": 400,
      "line-height": auto,
      "letter-spacing": 0.5,
      "font-family": $barlow,
      "text-transform": false,
    ),
    "body-2": (
      "size": 16px,
      "weight": 400,
      "line-height": auto,
      "letter-spacing": 0.25,
      "font-family": $barlow,
      "text-transform": false,
    ),
    "button": (
      "size": 16px,
      "weight": 700,
      "line-height": auto,
      "letter-spacing": 1.25px,
      "font-family": $barlow,
      "text-transform": uppercase,
    ),
    "caption": (
      "size": 13px,
      "weight": 400,
      "line-height": auto,
      "letter-spacing": 0.4,
      "font-family": $barlow,
      "text-transform": false,
    ),
    "overline": (
      "size": 10px,
      "weight": 500,
      "line-height": auto,
      "letter-spacing": 1.5px,
      "font-family": $barlow,
      "text-transform": uppercase,
    ),
  ),
  $heading
);

/* ------------------------------ 
    GRID LAYOUT 
------------------------------ */
$gutter-padding-horizontal: 1.5rem;
$gutter-margin-vertical: 1rem;

$sm: 0em;
$md: 34.5625em; // 553px / 16px
$lg: 56.3125em; // 901px / 16px
$xl: 87.5625em; // 1401px / 16px

$width-container-sm: 90%;
$width-container-md: 85%;
$width-container-lg: 123.3rem;
$width-container-xl: 130rem;

.container {
  width: $width-container-sm;
  margin: 0 auto;
  position: relative;
  @include breakpoint(xs) {
    .MuiContainer-root{
      padding: 0 !important;
    }
  }
  @include breakpoint(md) {
    width: 90%;
    max-width: 1168px;
    // .MuiContainer-root{
    //   padding: 0 !important;
    // }
  }
  @include breakpoint(lg) {
    max-width: 1168px;
  }
  @include breakpoint(xl) {
    max-width: 1168px !important;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$gutter-padding-horizontal;
  margin-right: -$gutter-padding-horizontal;

  [class*="flex-sm"] {
    width: 100%;
    max-width: 100%;
    padding-right: $gutter-padding-horizontal;
    padding-left: $gutter-padding-horizontal;
    min-width: 0.1rem;
    margin-bottom: $gutter-margin-vertical;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .test {
    background-color: #f1b434;
    text-align: center;
    border: 1px solid #000;
  }

  .flex-sm-1,
  .flex-sm-2,
  .flex-sm-3,
  .flex-sm-4,
  .flex-sm-5,
  .flex-sm-6,
  .flex-sm-7,
  .flex-sm-8,
  .flex-sm-9,
  .flex-sm-10,
  .flex-sm-11,
  .flex-sm-12 {
    @include breakpoint(sm) {
      margin-bottom: 0;
    }
  }

  .flex-sm-1 {
    @include breakpoint(sm) {
      @include grid(12);
    }
  }
  .flex-sm-2 {
    @include breakpoint(sm) {
      @include grid(6);
    }
  }
  .flex-sm-3 {
    @include breakpoint(sm) {
      @include grid(4);
    }
  }
  .flex-sm-4 {
    @include breakpoint(sm) {
      @include grid(3);
    }
  }
  .flex-sm-5 {
    @include breakpoint(sm) {
      flex: 0 0 percentage(5 / 12);
      max-width: percentage(5 / 12);
    }
  }
  .flex-sm-6 {
    @include breakpoint(sm) {
      @include grid(2);
    }
  }
  .flex-sm-7 {
    @include breakpoint(sm) {
      flex: 0 0 percentage(7 / 12);
      max-width: percentage(7 / 12);
    }
  }
  .flex-sm-8 {
    @include breakpoint(sm) {
      flex: 0 0 percentage(8 / 12);
      max-width: percentage(8 / 12);
    }
  }
  .flex-sm-9 {
    @include breakpoint(sm) {
      flex: 0 0 percentage(9 / 12);
      max-width: percentage(9 / 12);
    }
  }
  .flex-sm-10 {
    @include breakpoint(sm) {
      flex: 0 0 percentage(10 / 12);
      max-width: percentage(10 / 12);
    }
  }
  .flex-sm-11 {
    @include breakpoint(sm) {
      flex: 0 0 percentage(11 / 12);
      max-width: percentage(11 / 12);
    }
  }
  .flex-sm-12 {
    @include breakpoint(sm) {
      @include grid(1);
    }
  }

  .flex-md-1,
  .flex-md-2,
  .flex-md-3,
  .flex-md-4,
  .flex-md-5,
  .flex-md-6,
  .flex-md-7,
  .flex-md-8,
  .flex-md-9,
  .flex-md-10,
  .flex-md-11,
  .flex-md-12 {
    @include breakpoint(md) {
      margin-bottom: 0;
    }
  }

  .flex-md-1 {
    @include breakpoint(md) {
      @include grid(12);
    }
  }

  .flex-md-2 {
    @include breakpoint(md) {
      @include grid(6);
    }
  }

  .flex-md-3 {
    @include breakpoint(md) {
      @include grid(4);
    }
  }

  .flex-md-4 {
    @include breakpoint(md) {
      @include grid(3);
    }
  }

  .flex-md-5 {
    @include breakpoint(md) {
      flex: 0 0 percentage((5 / 12));
      max-width: percentage((5 / 12));
    }
  }

  .flex-md-6 {
    @include breakpoint(md) {
      @include grid(2);
    }
  }

  .flex-md-7 {
    @include breakpoint(md) {
      flex: 0 0 percentage((7 / 12));
      max-width: percentage((7 / 12));
    }
  }

  .flex-md-8 {
    @include breakpoint(md) {
      flex: 0 0 percentage((8 / 12));
      max-width: percentage((8 / 12));
    }
  }

  .flex-md-9 {
    @include breakpoint(md) {
      flex: 0 0 percentage((9 / 12));
      max-width: percentage((9 / 12));
    }
  }

  .flex-md-10 {
    @include breakpoint(md) {
      flex: 0 0 percentage((10 / 12));
      max-width: percentage((10 / 12));
    }
  }

  .flex-md-11 {
    @include breakpoint(md) {
      flex: 0 0 percentage((11 / 12));
      max-width: percentage((11 / 12));
    }
  }

  .flex-md-12 {
    @include breakpoint(md) {
      @include grid(1);
    }
  }
  .flex-lg-1,
  .flex-lg-2,
  .flex-lg-3,
  .flex-lg-4,
  .flex-lg-5,
  .flex-lg-6,
  .flex-lg-7,
  .flex-lg-8,
  .flex-lg-9,
  .flex-lg-10,
  .flex-lg-11,
  .flex-lg-12 {
    @include breakpoint(lg) {
      margin-bottom: 0;
    }
  }

  .flex-lg-1 {
    @include breakpoint(lg) {
      @include grid(12);
    }
  }

  .flex-lg-2 {
    @include breakpoint(lg) {
      @include grid(6);
    }
  }

  .flex-lg-3 {
    @include breakpoint(lg) {
      @include grid(4);
    }
  }

  .flex-lg-4 {
    @include breakpoint(lg) {
      @include grid(3);
    }
  }

  .flex-lg-5 {
    @include breakpoint(lg) {
      flex: 0 0 percentage((5 / 12));
      max-width: percentage((5 / 12));
    }
  }

  .flex-lg-6 {
    @include breakpoint(lg) {
      @include grid(2);
    }
  }

  .flex-lg-7 {
    @include breakpoint(lg) {
      flex: 0 0 percentage((7 / 12));
      max-width: percentage((7 / 12));
    }
  }

  .flex-lg-8 {
    @include breakpoint(lg) {
      flex: 0 0 percentage((8 / 12));
      max-width: percentage((8 / 12));
    }
  }

  .flex-lg-9 {
    @include breakpoint(lg) {
      flex: 0 0 percentage((9 / 12));
      max-width: percentage((9 / 12));
    }
  }

  .flex-lg-10 {
    @include breakpoint(lg) {
      flex: 0 0 percentage((10 / 12));
      max-width: percentage((10 / 12));
    }
  }

  .flex-lg-11 {
    @include breakpoint(lg) {
      flex: 0 0 percentage((11 / 12));
      max-width: percentage((11 / 12));
    }
  }

  .flex-lg-12 {
    @include breakpoint(lg) {
      @include grid(1);
    }
  }
  .flex-xl-1,
  .flex-xl-2,
  .flex-xl-3,
  .flex-xl-4,
  .flex-xl-5,
  .flex-xl-6,
  .flex-xl-7,
  .flex-xl-8,
  .flex-xl-9,
  .flex-xl-10,
  .flex-xl-11,
  .flex-xl-12 {
    @include breakpoint(xl) {
      margin-bottom: 0;
    }
  }

  .flex-xl-1 {
    @include breakpoint(xl) {
      @include grid(12);
    }
  }

  .flex-xl-2 {
    @include breakpoint(xl) {
      @include grid(6);
    }
  }

  .flex-xl-3 {
    @include breakpoint(xl) {
      @include grid(4);
    }
  }

  .flex-xl-4 {
    @include breakpoint(xl) {
      @include grid(3);
    }
  }

  .flex-xl-5 {
    @include breakpoint(xl) {
      flex: 0 0 percentage((5 / 12));
      max-width: percentage((5 / 12));
    }
  }

  .flex-xl-6 {
    @include breakpoint(xl) {
      @include grid(2);
    }
  }

  .flex-xl-7 {
    @include breakpoint(xl) {
      flex: 0 0 percentage((7 / 12));
      max-width: percentage((7 / 12));
    }
  }

  .flex-xl-8 {
    @include breakpoint(xl) {
      flex: 0 0 percentage((8 / 12));
      max-width: percentage((8 / 12));
    }
  }

  .flex-xl-9 {
    @include breakpoint(xl) {
      flex: 0 0 percentage((9 / 12));
      max-width: percentage((9 / 12));
    }
  }

  .flex-xl-10 {
    @include breakpoint(xl) {
      flex: 0 0 percentage((10 / 12));
      max-width: percentage((10 / 12));
    }
  }

  .flex-xl-11 {
    @include breakpoint(xl) {
      flex: 0 0 percentage((11 / 12));
      max-width: percentage((11 / 12));
    }
  }

  .flex-xl-12 {
    @include breakpoint(xl) {
      @include grid(1);
    }
  }
}
