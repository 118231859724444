@import '../../../styles/variables.scss';
@import '../../../styles/mixins/mixin.scss';

.login {
    &__checkbox {
        display: flex;
        align-items: center;
        justify-content: center;
        input[type="checkbox"] + span {
            font-weight: 400;
            font-size: 1rem;
        }
        input[type="checkbox"] {
            width: 18px;
            height: 18px;
        }
        input[type="checkbox"]:checked {
            background-color: $primary;
        }
    }

    &__footer {
        @include breakpoint(xs) {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column-reverse;
        }
    }

    &__btn{
        width: 100%; 
        display: flex; 
        justify-content: space-around;
        align-items: center;
        @include breakpoint(xsl) {
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;
        }
        button {
            width: 185px;
            margin-right: 10px;
            @include breakpoint(xsl) {
                width: 100%;
                margin-bottom: 10px;
            }
        }
    }
}