@import "./../../../styles/variables.scss";
.wrapper__input {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  position: relative;
  height: 100%;

  input {
    width: 100%;
    height: 100%;
    padding: 0 1rem;
    outline: 0;
    border: 1px solid #ecf0f1;
    box-shadow: 0px 2px 20px #ecf0f1;
    border-radius: 100px;

    font-family: Arial;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #53565A;
    margin-left: -1%;
  }
  
  // input:invalid{
  //   border: 2px solid #e4002b;
  // }

  textarea {
    width: 100%;
    padding: 1rem;
    outline: 0;
    font-size: 1rem;
    font-family: $barlow;
    border: 1px solid #ecf0f1;
    box-shadow: 0px 2px 20px #ecf0f1;
    border-radius: 20px;
  }

  select {
    width: 100%;
    padding-left: 13px;
    padding-right: 13px;
    outline: 0;
    font-size: 1rem;
    height: 100%;
    border: 1px solid #ecf0f1;
    box-shadow: 0px 2px 20px #ecf0f1;
    border-radius: 20px;

    font-family: Arial;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #53565A;
  }
  select::after {
    content: '';
    position: absolute;
    right: 0;
    top: 20px;
    width: 0;
    height: 0;
    padding: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid cornflowerblue;
  }

  label {
    padding: 0 1rem;
    color: #53565a;
    font-size: 1rem;
    font-weight: 400;
    line-height: 20px;
    position: absolute;
    transform: translate(0, 18px) scale(1);
    transform-origin: top left;
    transition: all 0.2s ease-out;
  }
  &:focus-within {
    label {
      transform: translate(0, -20px) scale(0.75);
      color: $primary;
      font-weight: 500;
      font-size: 1rem;
    }
  }
}

.active {
  transform: translate(0, -20px) scale(0.75) !important;
  color: $primary !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
}

.required {
  font-size: 12px;
  color: $gray_lighten-2;
  margin: 0.5rem 1rem 0;
}
