//style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 2fr))', alignItems: 'center' }}
.container__padding {
  @media only screen and (max-width: 600px) {
    padding: 0px !important;
  }

  @media only screen and (min-width: 768px) {
    padding: 0 97px 0 59px !important;
  }
}

.grid__container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 2fr));
  align-items: center;
}

.wrapper__flex {
  display: flex;
}

.wrapper__title {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  &__btn {
    justify-self: flex-end;
  }
}

.wrapper {
  &__content {
    &__right {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.grid__wrapper {
  margin-bottom: 31px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 1rem;
  &__2xl {
    margin: 31px 0;
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    gap: 4rem;
  }
}
