.banner{
  padding: 0;
  margin: 20px 0;
  overflow: hidden;
  position: relative;
}

.leftSide{ 
  width: 100%; 
  min-height: 300px; 
}

.displayTitle{
  text-align: center;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 50vh;
  h1 {
    width: 100%;
  }
}

.subtitle{
  text-align: center;
}

.wrapper__loader {
  width: 120px;
}